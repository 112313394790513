<template>
  <div class="stylist mt-5 mb-5 pt-5 pb-5"
       :class="{
          'on': isIntersectingElement || isAbove,
          'off': !isIntersectingElement && !isAbove,
        }">
    <div class="who">
      <div class="image">
        <div class="circle"><img v-if="stylist.image" :alt="stylist.name + ' stylist at Salon Oui Bellingham'" :src="getImg(stylist.image)" width="400"></div>
        <img class="monsterra" alt="Monsterra" v-if="stylist.image" :src="getImg('monsterra_resized.png')" width="150">
      </div>
      <div class="text">
        <h3 class="name">{{ stylist.name }}</h3>
        <p class="title">{{ stylist.title }}</p>
      </div>
    </div>
    <intersection-observer
        sentinal-name="sentinal-name"
        @on-intersection-element="onIntersectionElement"
        @on-above-element="onAboveElement"
    ></intersection-observer>
    <div v-html="stylist.bio" class="bio"></div>
    <p><strong>Contact me for an appointment:</strong></p>
    <p v-if="stylist.phone">
      <a :href="'tel:' + stylist.phone">{{ stylist.phone }}</a>
    </p>
    <p v-if="stylist.email">
      <a :href="'mailto:' + stylist.email">{{ stylist.email }}</a>
    </p>
    <p v-if="stylist.instagramHandle">
      Instagram <a :href="'https://instagram.com/' + stylist.instagramHandle" target="_blank">@{{ stylist.instagramHandle }}</a>
    </p>
    <p v-if="stylist.website">
      <a :href="stylist.website" target="_blank">{{ stylist.websiteLabel }}</a>
    </p>
  </div>
</template>

<script>
import IntersectionObserver from "@/components/IntersectionObserver";
export default {
  name: 'StylistCard',
  components: {IntersectionObserver},
  props: {
    stylist: Object,
  },
  data() {
    return {
      isIntersectingElement: false,
      isAbove: false,
      getImg(img) {
        return require(`../assets/${img}`);
      }
    };
  },
  methods: {
    onIntersectionElement(value) {
      this.isIntersectingElement = value;
    },
    onAboveElement(value) {
      this.isAbove = value;
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.stylist {
  transition: all .5s;
  position: relative;
  margin: 100px auto;
}
.on {
  transition-delay: .5s;
  opacity: 1;
  transform: translateY(0);
}
.off {
  opacity: 0;
  transform: translateY(30px);
}
.image {
  position: relative;
  width: 300px;
  height: 300px;
  margin: 20px auto;
}
.circle {
  border-radius: 100%;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.circle img {
  width: 100%;
}
.monsterra {
  position: absolute;
  bottom: -14px;
  left: -32px;
  transform: rotate(42deg);
  width: 122px;
}
.name {
  font-size: 40px;
  margin: 0 0 20px;
}
.title {
  font-size: 24px;
  margin: 0 10px 10px 0;
}
@media(min-width: 768px)
{
  .who {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .image {
    width: 400px;
    height: 400px;
    margin: 20px;
  }
  .monsterra {
    left: -43px;
    width: 150px;
  }
  .text {
    text-align: right;
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 20px;
  }
  .name {
    font-size: 50px;
  }
  .title {
    font-size: 30px;
  }
}
</style>
