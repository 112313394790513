<template>
  <div :ref="sentinalName" class="observer relative" />
</template>
<script>
export default {
  name: 'IntersectionObserver',
  props: {
    sentinalName: {
      type: String,
      required: true,
    },
  },
  data(){
    return {
      isIntersectingElement: false,
      previousScroll: 0,
      isAbove: false,
    }
  },
  watch: {
    isIntersectingElement: function(value) {
      this.$emit('on-intersection-element', value)
    },
    isAbove: function(value) {
      this.$emit('on-above-element', value)
    },
  },
  mounted() {
    const sentinal = this.$refs[this.sentinalName]
    const handler = (entries) => {
      if (entries[0].isIntersecting) {
        this.isIntersectingElement = true
      }
      else {
        this.isIntersectingElement = false

        if(window.scrollY > this.previousScroll) {
          this.isAbove = true;
        } else {
          this.isAbove = false;
        }
      }
      this.previousScroll = window.scrollY;
    }
    const observer = new window.IntersectionObserver(handler)
    observer.observe(sentinal)
  },
}
</script>

<style>
.observer {
  position: absolute;
  z-index: -1;
  top: 30%;
  left: 0;
  bottom: 0;
  right: 0;
}
</style>