<template>
  <Slide id="menu" :closeOnNavigation="true">
    <a id="menu-stylists" class="menu-item" @click="scrollStylists()">
      <span>Stylists</span>
    </a>
    <a id="menu-salon" class="menu-item" @click="scrollSalon()">
      <span>Salon</span>
    </a>
  </Slide>
  <main id="page-wrap">
    <div class="container-sm">
      <div id="header">
        <h1><span class="desktop">Welcome to </span>Salon Oui<span class="hidden"> Bellingham</span></h1>
      </div>
      <div id="intro">
        <img id="graphic" alt="Salon Oui" src="./assets/oui-sm.png">
        <h1>Bellingham, Washington</h1>
        <p>Salon Oui provides professional salon and aesthetic services for everyday beauty. Everyone deserves to feel stunning, pampered and glamorous in their lives.  Say yes to Salon Oui!</p>
      </div>
      <div id="stylists" ref="stylists"></div>
      <section class="stylists">
        <h2>Our Stylists</h2>
        <StylistCard v-for="stylist in stylists" :key="stylist.id" :stylist="stylist"></StylistCard>
      </section>
      <div id="salon" ref="salon"></div>
      <section class="salon">
        <h2>Our Salon</h2>
        <p>Salon Oui is located at 1420 #F, King St, Bellingham, WA</p>
        <p><a href="https://goo.gl/maps/68uGoxhKQvWD8ScF6" target="_blank">Get Directions</a></p>
        <swiper
            :slides-per-view="getSlidesPerView()"
            :space-between="50"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
        >
          <swiper-slide><img src="./assets/salon-oui-reception.jpg" alt="Salon Oui Bellingham Reception" style="width:100%;"></swiper-slide>
          <swiper-slide><img src="./assets/salon-oui-waiting.jpg" alt="Salon Oui Bellingham waiting area" style="width:100%;"></swiper-slide>
          <swiper-slide><img src="./assets/salon-oui-floor.jpg" alt="Salon Oui Bellingham floor" style="width:100%;"></swiper-slide>
          ...
        </swiper>
      </section>
      <section id="foot">
        Website by <a href="https://rivitdesign.com" target="_blank">RIVIT Design</a>
      </section>
    </div>
  </main>
</template>

<script>
import stylists from './constants/stylists.json'
import StylistCard from "@/components/StylistCard";
import { Slide } from "vue3-burger-menu";
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

export default {
  name: 'App',
  components: {
    StylistCard,
    Slide,
    Swiper,
    SwiperSlide
  },
  data() {
    return { stylists: stylists}
  },
  mounted() {
    setTimeout(() => {
        document.getElementById("graphic").classList.add("loaded");
    }, 600);

    this.salonSection = this.$refs.salon;
    this.stylistsSection = this.$refs.stylists;
  },
  methods: {
    getSlidesPerView() {
      if(window.innerWidth > 768) {
        return 3;
      } else {
        return 1;
      }
    },
    scrollStylists() {
      if(this.stylistsSection) {
        this.stylistsSection.scrollIntoView({behavior: "smooth"})
      }
    },
    scrollSalon() {
      if(this.salonSection) {
        this.salonSection.scrollIntoView({behavior: "smooth"})
      }
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #213d32;
  /*overflow-x: hidden;*/
  background: #fffbf8;
  font-size: 18px;
  scroll-behavior: smooth;
}
.hidden {
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  margin: -1px!important;
  overflow: hidden;
  padding: 0;
  position: absolute!important;
  width: 1px;
}

a {
  color: teal;
  cursor: pointer;
}

#header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: #fffbf8;

}
#header h1 {
  margin: 26px auto;
  text-transform: uppercase;
  font-weight: bold;
}
#intro {
  padding-top: 130px;
}
#intro h1 {
  text-transform: uppercase;
  font-weight: bold;
  margin: 40px auto;
}
#graphic {
  max-width: 100%;
  max-height: 70vh;
  margin: 0 auto;
  transition: all 1s;
  opacity: 0;
  transform: translateY(15px) scale(1.2);
}
#graphic.loaded {
  opacity: 1;
  transform: translateY(0) scale(1);
}
.bm-burger-button {
  position: fixed !important;
  z-index: 11 !important;
}
.bm-menu {
  background: #215649 url(http://localhost:8080/img/monsterra-double.6453d68f.png) 60% 97% / 375px no-repeat !important;
}
section {
  margin: 200px auto 50px;
}
#foot {
  padding-bottom: 30px;
}
h2 {
  font-weight: bold !important;
  font-size: 50px !important;
}
@media(max-width: 767px) {
  .desktop {
    clip: rect(0 0 0 0);
    border: 0;
    height: 1px;
    margin: -1px !important;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
  }
  .bm-burger-button {
    left: 27px !important;
    top: 27px !important;
  }
}

  .container-sm {
    max-width: 1100px !important;
    padding: 0px 20px;
    margin: 0 auto;
  }
</style>
